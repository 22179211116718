import { instances } from '../default-setup';

export const getServiceByName = (service) => {
    return instances.get(`slo/${service}/`);
};

export const getServices = () => {
    return instances.get(`slo/services/`);
};

export const createService = (data) => instances.post('slo/', data);

export const updateService = (service, data) =>
    instances.put(`slo/services/${service}/`, data);

export const createFeature = (service, data) =>
    instances.put(`slo/services/${service}/`, data);

export const updateFeature = (service, feature, data) =>
    instances.put(`slo/${service}/${feature}/`, data);

export const deleteSerive = (service) =>
    instances.delete(`slo/${service}/`);

export const deleteFeature = (service, feature) =>
    instances.delete(`slo/${service}/${feature}/`);

export const tryFilters = (service, data) => {
    // console.log(data);
    return instances.post(`slo/${service}/filters/`, data);
};

export const getErrorBudgets = (
    service,
    startDate,
    endDate,
    feature,
    step = '24 hours'
) => {
    return instances.get(
        `slo/${service}/${feature}/?date_start=${startDate}&date_end=${endDate}&step_size=${step}`
    );
};

export const getCronjobStatus = () => {
    return instances.get(`slo/last_run/`);
};

export const getCronjobLogs = (jobId) => instances.get(`slo/last_run/${jobId}`);
